// src/pages/infos/paneldata.js
import kofiLogo from '../../assets/kofi_logo.png';
import discordLogo from '../../assets/discord_logo.png';
import patreonLogo from '../../assets/patreon_logo.png';

const panelData = [
    { 
      title: 'About Me', 
      content: (
        <div>
            <p><strong><u>Work :</u></strong></p>
            <ul>
            <p>Im creating 3d designs from scratch. I make them fitting xiv and im aiming mostly at giving a refreshing, pleasing and easy experience to all my supporters through my creations.</p>
            <p>Im mostly focusing on clothes and accessories, and some body sculpt. I focus mainly on females, but I sometimes do males and transgenders</p>
            <p>Im always looking to learn new things and work on new things. Knowledge is something i value highly.</p>
            </ul>
            <p><strong><u>My self :</u></strong></p>
            <ul>
            <p>Smiling ... Happyness, those are the two first word that could come to mind if i had to speak about me~</p>
            <p>I'am always trying to take the best part of everything, to see the glass half full and not half empty.</p>
            <p>I believe that by making others happy, they will always bring it back to me !</p>
            </ul>
        </div>
      ),
    },
    {
      title: 'Permissions & Terms of Service',
      id : 'tos',
      content: (
        <div style={{ lineHeight: '1.6' }}>
          <h2 style={{ textAlign: 'center' }}>Terms of Service (ToS)</h2>
          <p><strong>Effective Date:</strong> 23th of december 2024</p>

          <p><strong>Summurized :</strong> All this text below is just for legal reasons. I wish i didnt had to write all this, but thats how it works.</p>
          <p><strong>Paid :</strong> For ur personal use/edit only. Dont share it.  </p>
          <p><strong>Free :</strong> Do whatever as long as you credit me. Dont port in other games without my permission.  </p>
          <h3>1. Ownership and License</h3>
          <p>
            <strong>1.1</strong> All digital content (the <em>“Content”</em>) available on this
            website—whether free or paid—is created or licensed by <strong>ApplenzoDesigns</strong> (
            <em>“Me,” “I,” “my,” “we,” or “us,”</em> as context requires).
          </p>
          <p>
            <strong>1.2</strong> By purchasing, downloading, or otherwise accessing the Content,
            you (<em>“User,” “you,” or “your”</em>) are granted a limited, non-transferable,
            non-exclusive license for personal use only, subject to these Terms of Service.
          </p>
          <p>
            <strong>1.3</strong> Some Content includes third-party or licensed assets. Ownership of
            those assets remains with their original licensors. If you want more details about specific
            assets used in my Content, please contact me.
          </p>
          <p>
            <strong>1.4</strong> All purchases made are considered final.
             However, if you are not satisfied with the product for any reason, you may contact me directly to request a refund.
              Refunds will be reviewed on a case-by-case basis,
             and I reserve the right to approve or deny any refund request at my sole discretion.
          </p>
          <p>
            <strong>1.5</strong> These Terms of Service apply to all Content I have provided since I began publishing on Kofi, Patreon, this website, or any place I have officially shared my Content, and they continue to apply to all Content published or downloaded hereafter.
          </p>
    
          <h3>2. General Usage Rules</h3>
          <p>
            <strong>2.1 Personal Use:</strong> You may use the Content privately for yourself,
            including making personal edits or modifications, provided you do not violate other
            sections of these Terms.
          </p>
          <p>
            <strong>2.2 Prohibited Uses:</strong> Unless specifically authorized in writing by me:
          </p>
          <ul>
            <li>
              You may not redistribute, re-upload, or share the Content—whether free or paid—outside of
              this website, including sharing with friends or posting on external platforms.
            </li>
            <li>
              You may not use my Content for commercial purposes, such as reselling or offering it as
              part of paid commissions, without explicit permission.
            </li>
            <li>You may not port my Content to other games or platforms.</li>
            <li>
              You may not use my Content in any manner that violates applicable laws or regulations.
            </li>
          </ul>
    
          <h3>3. Paid Content</h3>
          <p>
            <strong>3.1 Private &amp; Early Access Content:</strong> All paid Content (including early
            access, mods, and other downloadable materials) is strictly private and for personal use.
          </p>
          <p>
            <strong>3.2 Modifications/Edits:</strong> You may make personal modifications, resizes,
            or texture edits only for your own use, provided you do not share these edits publicly.
          </p>
          <p>
            <strong>3.3 Commissions and Derivatives:</strong>
          </p>
          <ul>
            <li>
              If you wish to commission or offer any modification or derivative work of my paid Content
              to another party, you must first obtain my written permission.
            </li>
            <li>
              I reserve the right to perform or refuse commissions or modifications of my Content at my
              sole discretion.
            </li>
          </ul>
    
          <h3>4. Free Content</h3>
          <p>
            <strong>4.1 Usage Permissions:</strong> Free Content may be used more liberally:
          </p>
          <ul>
            <li>
              You may create modifications, resizes, or texture edits, provided you credit
              <strong> ApplenzoDesigns</strong> and link to the original post as a required file.
            </li>
            <li>
              You may use my public assets in private commissions, but only with clear credit to me and
              a link to the original Content.
            </li>
          </ul>
          <p>
            <strong>4.2 Restrictions:</strong> The same Prohibited Uses in Section 2.2 apply.
            Specifically, you may not port my free Content to other games or platforms unless you have
            received my explicit permission.
          </p>
    
          <h3>5. Disclaimer of Responsibility</h3>
          <p>
            <strong>5.1 User’s Own Responsibility:</strong> These mods or digital materials are
            intended for personal use in a video game or related environment. <strong>ApplenzoDesigns</strong> is
            not responsible for how you choose to use, modify, or incorporate this Content. You assume
            full responsibility for any actions you take with the Content.
          </p>
          <p>
            <strong>5.2 Third-Party Licenses:</strong> Some Content may incorporate licensed assets
            from other creators. Ownership and usage terms of those third-party assets remain subject
            to their respective license agreements.
          </p>
    
          <h3>6. Copyright and Intellectual Property</h3>
          <p>
            <strong>6.1</strong> All rights, titles, and interest in and to the Content remain solely
            with <strong>ApplenzoDesigns</strong> or with any applicable third-party licensors. Any
            unauthorized use, distribution, or reproduction of the Content may violate intellectual
            property laws.
          </p>
          <p>
            <strong>6.2</strong> I do not transfer ownership of the Content to you. This is a license
            agreement for personal use only.
          </p>
    
          <h3>7. Violations and Enforcement</h3>
          <p>
            <strong>7.1 License Revocation:</strong> I reserve the right to revoke your license and
            access to any or all of my Content if you breach these Terms. This can include, but is not
            limited to, removing your access to future updates, downloads, or membership benefits.
          </p>
          <p>
            <strong>7.2 Legal Remedies:</strong> In addition to license revocation, I reserve the right
            to pursue any legal remedies available under applicable law if you fail to comply with this
            ToS.
          </p>
          <p>
            <strong>7.3 Data Retention for Enforcement:</strong> In the event of a breach of these Terms, we reserve the right to retain relevant personal data to enforce our Terms of Service, protect our intellectual property, and prevent future violations.
          </p>
    
          <h3>8. Liability and Disclaimers</h3>
          <p>
            <strong>8.1 No Warranty:</strong> The Content is provided “as is” without any warranties of
            any kind, express or implied. I do not warrant that the Content will meet your requirements
            or that access will be uninterrupted or error-free.
          </p>
          <p>
            <strong>8.2 Limitations of Liability:</strong> To the maximum extent permitted by law, I
            shall not be liable for any indirect, incidental, consequential, or special damages arising
            out of or related to your use or inability to use the Content.
          </p>
    
          <h3>9. Changes to These Terms</h3>
          <p>
            I may update these Terms from time to time. The effective date of the current version will
            appear at the top of this document. By continuing to use or access the Content after any
            revisions become effective, you agree to be bound by the updated Terms.
          </p>
    
          <h3>10. Contact Information</h3>
          <p>
            For questions regarding this ToS or to request permission for uses not covered here, please
            contact: <em> #applenzo on discord</em>
          </p>
        </div>
      )
    },
    {
      title: 'Privacy Policy',
      id: 'privacy',
      content: (
        <div style={{ lineHeight: '1.6' }}>
          <h2 style={{ textAlign: 'center' }}>Privacy Policy</h2>
          <p><strong>Effective Date:</strong> December 23, 2024</p>

          <p><strong>Summurized :</strong> All this text below is just for legal reasons. I wish i didnt had to write all this, but thats how it works.
          In short, I dont take more informations that is not already given by the payment platform such as stripe/paypal, or website such as patreon/kofi.
          So by using this website, you wont give me more informations you are already giving me by using kofi/patreon. And anyway, i use those information
          just to make this website work the best. </p>

          <h3>1. Introduction</h3>
          <p>
            Welcome to <strong>ApplenzoDesigns</strong> ("we," "us," or "our"). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you visit our website or use our services.
          </p>
    
          <h3>2. Information We Collect</h3>
          <p><strong>2.1 Personal Information</strong></p>
          <p>
            We may collect information that you voluntarily provide to us when you:
          </p>
          <ul>
            <li>Register on our website</li>
            <li>Make a purchase, using infos kofi/patreon gives us</li>
            <li>Contact us for support or inquiries</li>
          </ul>
          <p><strong>Types of Personal Information:</strong></p>
          <ul>
            <li><strong>Name/Username</strong></li>
            <li><strong>Email</strong></li>
          </ul>
    
          <p><strong>2.2 Non-Personal Information</strong></p>
          <p>
            We may collect non-identifiable information that does not directly reveal your identity, such as:
          </p>
          <ul>
            <li><strong>Usage Data:</strong> Information about how you access and use our website (e.g., pages visited, time spent on pages).</li>
            <li><strong>Device Information:</strong> Details about the device you use to access our services (e.g., IP address, browser type, operating system).</li>
          </ul>
          <li><strong>Ps:</strong> I currently use none of them.</li>
    
          <p><strong>2.3 Embedded Licenses and Monitoring Data</strong></p>
          <p>
            To protect our digital content, we embed licenses within our files. These licenses may contain unique identifiers that help us monitor and detect unauthorized sharing or distribution of our content.
          </p>
    
          <h3>3. How We Use Your Information</h3>
          <p>
            We use the collected data for various purposes, including:
          </p>
          <ul>
            <li><strong>Providing Services:</strong> To deliver the content and services you have requested.</li>
            <li><strong>Improving Our Website:</strong> To enhance user experience and website functionality based on usage patterns.</li>
            <li><strong>Enforcing Terms of Service:</strong> To identify and take action against unauthorized sharing or distribution of our content.</li>
            <li><strong>Communication:</strong> To respond to your inquiries, provide customer support, and send you updates or promotional materials (with your consent).</li>
            <li><strong>Security:</strong> To protect against fraud, unauthorized access, and other security threats.</li>
          </ul>
    
          <h3>4. Data Sharing and Disclosure</h3>
          <p>
            We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:
          </p>
          <ul>
            <li>
              <strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you, provided they agree to keep your information confidential.
            </li>
            <li>
              <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., court orders, subpoenas).
            </li>
          </ul>
    
          <h3>5. Data Security</h3>
          <p>
            We implement a variety of security measures to maintain the safety of your personal information. These measures include:
          </p>
          <ul>
            <li><strong>Encryption:</strong> Protecting sensitive information using Secure Socket Layer (SSL) technology.</li>
            <li><strong>Access Controls:</strong> Restricting access to personal data to authorized personnel only.</li>
            <li><strong>Regular Monitoring:</strong> Conducting regular security assessments and audits to detect and prevent unauthorized access.</li>
          </ul>
    
          <h3>6. Data Retention</h3>
          <p>
            We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. When your data is no longer needed, we will securely delete or anonymize it.
          </p>
    
          <h3>7. Your Rights and Choices</h3>
          <p>
            Depending on your location, you may have the following rights regarding your personal information:
          </p>
          <ul>
            <li><strong>Access:</strong> Request access to the personal data we hold about you.</li>
            <li><strong>Correction:</strong> Request correction of any inaccurate or incomplete data.</li>
            <li><strong>Deletion:</strong> Request deletion of your personal data under certain conditions.</li>
            <li><strong>Opt-Out:</strong> Opt-out of receiving promotional communications from us.</li>
            <li><strong>Data Portability:</strong> Request the transfer of your data to another service provider.</li>
          </ul>
          <p>
            To exercise these rights, please contact us using the information provided in the <strong>Contact Information</strong> section below.
          </p>
    
          <h3>8. Cookies and Tracking Technologies</h3>
          <p>
            We use cookies and similar tracking technologies to enhance your browsing experience, analyze website usage, and improve our services. You can control the use of cookies through your browser settings.
          </p>
          <p><strong>Types of Cookies We Use:</strong></p>
          <ul>
            <li><strong>Essential Cookies:</strong> Necessary for the operation of our website.</li>
            <li><strong>Analytics Cookies:</strong> Help us understand how users interact with our website.</li>
            <li><strong>Functional Cookies:</strong> Enable enhanced functionality and personalization.</li>
          </ul>
    
          <h3>9. Children's Privacy</h3>
          <p>
            Our services are intended for users who are at least 18 years old. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it promptly.
          </p>
    
          <h3>10. International Data Transfers</h3>
          <p>
            Your information may be processed and stored in countries outside of your residence. By using our services, you consent to the transfer of your data to these countries, which may have different data protection laws than your jurisdiction.
          </p>
    
          <h3>11. Changes to This Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly. We encourage you to review this Privacy Policy periodically to stay informed about our data practices.
          </p>
    
          <h3>12. Contact Us</h3>
          <p>
            If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:
          </p>
          <ul>
            <li><strong>Discord:</strong> <a href="https://discord.com/channels/981126232097832981/1198280169610293289" target="_blank" rel="noopener noreferrer">#applenzo</a></li>
          </ul>
        </div>
      )
    },    
    {
      title: 'Commissions',
      content: (
        <div>
            <p><strong><u>How to commission something ?</u></strong><u>:</u> You must join my discord and open a commission ticket ! We will talk about all the details there<strong>&nbsp;</strong></p>
            <p><strong><u>Options</u></strong><u>:</u> If you commission something, you most likely will get the usual amount of options I offer on my stuff ! (A lot)<strong>&nbsp;</strong></p>
            <p><strong><u>Name&amp;Previews</u></strong>: By commissionning, if you would like to, you also get to choose the name and be in the previews !<strong><u></u></strong></p>
            <p><strong><u>Privacy</u></strong><u>:</u> I usually publish commissions for my patrons + maybe on Kofi.</p>
            <p><strong><u>Private Commissions</u></strong><u>:</u> If you want it to remain private, I may decline.</p>
            <p><strong><u>Price</u></strong><u>:</u> Prices are based on the request. I&rsquo;ll give a fair quote.</p>
            <p><u><strong>When(ETA)</strong></u><u>:</u> Depends on how much commissions im having when you ask. Can be a week up to month+. You will know as soon as you ask me !</p>
            <p><strong><u>Refusing</u></strong><u>:</u> I reserve the right to refuse requests that aren&rsquo;t within my field or comfort zone.</p>
            <div className="button-container">
          <a
            href="https://discord.gg/applenzo"
            target="_blank"
            rel="noopener noreferrer"
            className="info-button"
          >
            <img src={discordLogo} alt="Discord Logo" />
            Join my Discord
          </a>

          <a
          href="https://discord.com/channels/981126232097832981/983057731231551508"
          target="_blank"
          rel="noopener noreferrer"
          className="info-button"
        >
          <img src={discordLogo} alt="Discord Logo" />
          Commission Form (In my discord)
        </a>
        </div>
        </div>
      ),
    },
    { 
      title: 'Kofi Commissions (Collars/Upscales...)', 
      content: (
        <div>
            <p><strong><u>Ko-fi Commissions</u></strong>: Custom Chokers, Necklaces, Tags, Collars, and edits. Check it out before using the commission form!</p>
            <p><strong><u>Upscaling</u></strong>: My Upscaling commission is open on Kofi. I do upscaling only of my own work.</p>
            <div className="button-container">
          <a
            href="https://ko-fi.com/holowolfy/commissions"
            target="_blank"
            rel="noopener noreferrer"
            className="info-button"
          >
            <img src={kofiLogo} alt="Kofi Logo" />
            Check on Kofi Commissions
          </a>
        </div>
        </div>
      ),
    },
    // { 
    //   title: 'Body Sizes', 
    //   content: (
    //     <p>This is the content for Body Sizes.</p>
    //   ),
    // },
    // { 
    //   title: 'Patreon', 
    //   content: (
    //     <p>This is the content for Patreon.</p>
    //   ),
    // },
    // Add more panels here as needed
  ];
  
  export default panelData;