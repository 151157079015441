// src/components/Navbar.js

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png';
import './Navbar.css';
import moonIcon from '../assets/moon.png';
import sunIcon from '../assets/sun.png';

const Navbar = ({ isLoggedIn, accountName }) => {
  // Theme state
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  // Load theme preference from localStorage on component mount
  useEffect(() => {
    const currentTheme = localStorage.getItem('theme') || 'dark';
    if (currentTheme === 'dark') {
      document.documentElement.classList.add('dark-theme');
      setIsDarkTheme(true);
    }
  }, []);

  // Toggle theme handler
  const toggleTheme = () => {
    if (isDarkTheme) {
      document.documentElement.classList.remove('dark-theme');
      localStorage.setItem('theme', 'light');
    } else {
      document.documentElement.classList.add('dark-theme');
      localStorage.setItem('theme', 'dark');
    }
    setIsDarkTheme(!isDarkTheme);
  };

  return (
    <nav className="navbar">
      {/* Left Section: Logo */}
      <div className="navbar-logo">
        <NavLink to="/">
          <img src={logo} alt="Logo" />
        </NavLink>
      </div>

      {/* Center Section: Navigation Links */}
      <div className="navbar-center">
        <NavLink to="/" className="nav-link" activeclassname="active">
          Home
        </NavLink>
        <NavLink to="/library" className="nav-link" activeclassname="active">
          Your Library
        </NavLink>
        <NavLink to="/infos" className="nav-link" activeclassname="active">
          Infos
        </NavLink>
        <NavLink to="/inspiration-vote" className="nav-link" activeclassname="active">
          Inspiration Vote
        </NavLink>
      </div>

      {/* Right Section: Login/Account and Theme Toggle */}
      <div className="navbar-account">
        {/* Theme Toggle Button */}
        <button
          className="theme-toggle"
          onClick={toggleTheme}
          title="Toggle Dark Mode"
        >
          <img
            src={isDarkTheme ? moonIcon : sunIcon}
            alt="Theme Toggle"
            className="theme-icon"
          />
        </button>
        <NavLink to="/login" className="nav-link">
          {isLoggedIn ? accountName : 'Login'}
        </NavLink>


      </div>
    </nav>
  );
};

export default Navbar;
