import axios from 'axios';
import config from '../pages/config';

// Ensure Axios sends cookies with requests
axios.defaults.withCredentials = true;

const ApiService = {
  fetchPosts: () => {
    return axios.get(`${config.apiBaseUrl}/api/library/posts`);
  },

  fetchUserData: () => {
    return axios.get(`${config.apiBaseUrl}/api/auth/user`);
  },

  uploadImage: (postId, formData) => {
    return axios.post(`${config.apiBaseUrl}/api/upload/front/${postId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  acceptTos(userId) {
    return axios.post(`${config.apiBaseUrl}/api/library/accept-tos/${userId}`, {
      tosAccepted: true,
    });
  },

  updatePost: (postId, editedData) => {
    return axios.put(`${config.apiBaseUrl}/api/library/posts/${postId}`, editedData);
  },

  togglePost: (postId, enabled) => {
    return axios.put(`${config.apiBaseUrl}/api/library/posts/${postId}/toggle`, { enabled });
  },

  updateOrder: (postId, orderDate) => {
    return axios.put(`${config.apiBaseUrl}/api/library/posts/${postId}/order`, { orderDate });
  },
  // ADMIN ROUTES
  getTiers: () => {
    return axios.get(`${config.apiBaseUrl}/api/library/tiers`);
  },
  // ADMIN ROUTES
  getUsers: () => {
    return axios.get(`${config.apiBaseUrl}/api/admin/users`);
  },

  getUser: (databaseId) => {
    return axios.get(`${config.apiBaseUrl}/api/admin/users/${databaseId}`);
  },

  updateUser: (user) => {
    return axios.put(`${config.apiBaseUrl}/api/admin/users/${user._id}`, user);
  },

  getPostsByIds: (postIds) => {
    return axios.post(`${config.apiBaseUrl}/api/admin/posts/getByIds`, { postIds });
  },
  
  // New method to fetch all posts with 'postId' and 'title'
  getAllPosts: () => {
    return axios.get(`${config.apiBaseUrl}/api/admin/posts`);
  },
  // Routes for inspiration page :
  fetchInspirationPanels: () => {
    return axios.get(`${config.apiBaseUrl}/api/inspiration/panels`);
  },

  fetchInspirationTitle: () => {
    return axios.get(`${config.apiBaseUrl}/api/inspiration/title`);
  },

  updateInspirationTitle: (title) => {
    return axios.post(`${config.apiBaseUrl}/api/inspiration/title`, { title });
  },

  likeInspirationPanel: (panelId) => {
    return axios.post(`${config.apiBaseUrl}/api/inspiration/panels/${panelId}/like`);
  },

  unlikeInspirationPanel: (panelId) => {
    // The same endpoint is used to toggle the like
    return axios.post(`${config.apiBaseUrl}/api/inspiration/panels/${panelId}/like`);
  },

  patreonLikeInspirationPanel: (panelId) => {
    return axios.post(`${config.apiBaseUrl}/api/inspiration/panels/${panelId}/patreon-like`);
  },

  unpatreonLikeInspirationPanel: (panelId) => {
    // The same endpoint is used to toggle the patreon-like
    return axios.post(`${config.apiBaseUrl}/api/inspiration/panels/${panelId}/patreon-like`);
  },

  deleteInspirationPanel: (panelId) => {
    return axios.delete(`${config.apiBaseUrl}/api/inspiration/panels/${panelId}`);
  },

  createInspirationPanel: (panelData) => {
    return axios.post(`${config.apiBaseUrl}/api/inspiration/panels`, panelData);
  },

  updateInspirationPanel: (panelId, panelData) => {
    return axios.put(`${config.apiBaseUrl}/api/inspiration/panels/${panelId}`, panelData);
  },
    // Update inspiration config
  updateInspirationConfig(updates) {
    return axios.post(`${config.apiBaseUrl}/api/inspiration/config`, updates, {
    });
  },
  importPurchases(formData) {
    return axios.post(`${config.apiBaseUrl}/api/admin/import-purchases`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  // Reset inspiration likes
  resetInspirationLikes() {
    return axios.post(`${config.apiBaseUrl}/api/inspiration/reset-likes`, {}, {
    });
  }
};

export default ApiService;
