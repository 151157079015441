import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ApiService from '../services/ApiService';
import './InspirationPanel.css';
import HoloLove from '../assets/HoloLove.png'; // Import the emote image

const InspirationPanel = ({
  panel,
  isAuthenticated,
  userData,
  viewAsAdmin,
  setPanels,
  onEdit,
  config, // Receive config from parent
}) => {
  const [likes, setLikes] = useState(panel.likes || 0);
  const [userHasLiked, setUserHasLiked] = useState(panel.userHasLiked || false);

  const [showLikeEmote, setShowLikeEmote] = useState(false);

  const requiredTierIds = [
    '21786318',
    '8658988',
    '23507144',
    '23507184',
    '8825088',
    '8825009'
  ];

  useEffect(() => {
    setLikes(panel.likes || 0);
    setUserHasLiked(panel.userHasPatreonLiked || false);
  }, [panel]);

  const handleLike = async () => {
    
    if (!config.votingOpen) {
      toast.error('Voting is closed. You cannot like or unlike panels.');
      return;
    }
    if (!isAuthenticated) {
      toast.error('You must be logged in to like an inspiration.');
      return;
    }

    const hasRequiredTier =
      userData?.current_tier && requiredTierIds.includes(userData.current_tier);

    if (!hasRequiredTier) {
      toast.error('You must have at least a tier 2 subscription.');
      return;
    }

    try {
      if (userHasLiked) {
        await ApiService.unpatreonLikeInspirationPanel(panel.id);
        setLikes(likes - 1);
        setUserHasLiked(false);
      } else {
        await ApiService.patreonLikeInspirationPanel(panel.id);
        setLikes(likes + 1);
        setUserHasLiked(true);
        setShowLikeEmote(true); // Trigger the emote animation
      }
    } catch (error) {
      console.error('Error toggling like:', error);
      toast.error('Failed to toggle like.');
    }
  };

  const handleDelete = async () => {
    if (!viewAsAdmin) return;

    try {
      await ApiService.deleteInspirationPanel(panel.id);
      setPanels((prevPanels) => prevPanels.filter((p) => p.id !== panel.id));
      toast.success('Panel deleted successfully.');
    } catch (error) {
      console.error('Error deleting panel:', error);
      toast.error('Failed to delete panel.');
    }
  };
  // Determine the like count display
  const likeDisplay =
  config.mode === 'randomized' && config.votingOpen
    ? userHasLiked
      ? '?+1'
      : '?'
    : likes;
    
  return (
    <div className="inspiration-panel">
      <img src={panel.imageUrl} alt="Inspiration" className="inspiration-image" />
      <div className="like-buttons">
        <div className="like-button-container">
          <button onClick={handleLike} disabled={!config.votingOpen}>
            {userHasLiked && (
              <img
                src={HoloLove}
                alt="Liked"
                className="emote-inline"
              />
            )}
            Like ({likeDisplay})
          </button>
          {showLikeEmote && (
            <img
              src={HoloLove}
              alt="Emote"
              className="emote-animation"
              onAnimationEnd={() => setShowLikeEmote(false)}
            />
          )}
        </div>
      </div>
      {viewAsAdmin && (
        <div className="admin-actions">
          <button onClick={() => onEdit(panel)}>Edit</button>
          <button onClick={handleDelete}>Delete</button>
        </div>
      )}
    </div>
  );
};

export default InspirationPanel;
