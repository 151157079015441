// src/pages/Infos.js
import React, { useState, useContext,useEffect } from 'react';
import Navbar from '../components/Navbar';
import './Infos.css';
import panelData from './infos/paneldata';
import { AuthContext } from '../services/AuthContext';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS


const Infos = () => {
  const [openPanelIndex, setOpenPanelIndex] = useState(null);
  const [accountName, setAccountName] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const { isAuthenticated, userData } = useContext(AuthContext);


  const togglePanel = (index) => {
    setOpenPanelIndex(openPanelIndex === index ? null : index);
  };
  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true); // Start loading
      try {
        // Fetch user data to check admin status
        if (isAuthenticated === true) {
          setAccountName(userData.fullName);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data.');
        toast.error('Failed to fetch user data.');
      } finally {
        setLoading(false); // Stop loading
      }
    };
    fetchUser();
  }, [isAuthenticated, userData]);
  // 1) Detect if the URL has a hash, e.g. #tos
  useEffect(() => {
    if (window.location.hash) {
      // remove the '#' character
      const hash = window.location.hash.slice(1); // e.g. 'tos'

      // find the panel with matching ID
      const panelIndex = panelData.findIndex(panel => panel.id === hash);
      if (panelIndex !== -1) {
        setOpenPanelIndex(panelIndex);
      }
    }
  }, []);

  return (
    <>
      <Navbar isLoggedIn={!!accountName} accountName={accountName} />
      <div className="infos-page">
        {panelData.map((panel, index) => (
          <div
            key={index}
            className={`info-panel ${openPanelIndex === index ? 'open' : ''}`}
            onClick={() => togglePanel(index)}
          >
            <div className="panel-header">
              {panel.title}
              <span className={`arrow ${openPanelIndex === index ? 'open' : ''}`}>&#8250;</span>
            </div>
            {openPanelIndex === index && (
              <div className="panel-content">
                {panel.content}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Infos;